const ServiceStatus = () => {
  return (
    <div>
      <h1>OK</h1>
      <p>This service is running smoothly.</p>
    </div>
  );
};

export default ServiceStatus;
