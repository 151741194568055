import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { store } from "./services/state/store.ts";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { init as initApm } from "@elastic/apm-rum";

const theme = createTheme({
  // Add a theme here
});

// Initialize and export Elastic APM instance
export const apm = initApm({
  serviceName: "AmadeusConnector",
  serverUrl: process.env.REACT_APP_ELASTIC_SERVER_URL,
  serviceVersion: "1.0.0",
  environment: process.env.REACT_APP_ENVIRONMENT || "local",
  logLevel: "debug",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);
