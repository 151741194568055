import { Dispatch } from "redux";
import { addMessage } from "../state/message/messageSlice";
import { session } from "websdk-core";

/**
 * Handler for the 'tasks.created' event.
 *
 * @param dispatch - Redux dispatch function
 * @param registerInterceptor - Function to register the interceptor after setting the context
 */
export const handleCreatedTask = (
  dispatch: Dispatch<any>,
  registerInterceptor: () => void
) => {
  return async () => {
    try {
      const activeTask = await new Promise<any>((resolve, reject) => {
        session.getActiveTask().subscribe({
          next: (task: any) => resolve(task),
          error: (error: Error) => reject(error),
        });
      });

      if (activeTask?.subtype === "CMD" && activeTask.target) {
        session.setContext(activeTask.target).subscribe({
          next: () => {
            console.log(`Context set to: ${activeTask.target}`);
            registerInterceptor();
          },
          error: (errorRes: any) => {
            console.error("Error setting context:", errorRes);
            dispatch(
              addMessage(
                `Failed to set context: ${
                  errorRes instanceof Error ? errorRes.message : "Unknown error"
                }`
              )
            );
          },
        });
      }
    } catch (error) {
      console.error(
        "Error handling created task:",
        error instanceof Error ? error.message : "Unknown error"
      );
      dispatch(
        addMessage(
          `Failed to handle created task: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        )
      );
    }
  };
};

/**
 * Error handler for the 'tasks.created' event.
 *
 * @param dispatch - Redux dispatch function
 */
export const handleCreatedTaskError = (dispatch: Dispatch<any>) => {
  return (errorRes: any) => {
    console.error("Tasks Created Event notification error:", errorRes);
    dispatch(
      addMessage(
        `Failed to handle created event notification: ${
          errorRes instanceof Error ? errorRes.message : "Unknown error"
        }`
      )
    );
  };
};
