import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProfileInitializer from "./components/ProfileInitializer";
import MessageReceiver from "./components/MessageReceiver";
import ServiceStatus from "./components/ServiceStatus";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <ProfileInitializer />
              <MessageReceiver />
            </div>
          }
        />
        <Route path="/monitor" element={<ServiceStatus />} />
      </Routes>
    </Router>
  );
};

export default App;
