import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPopupId } from "../services/state/profile/profileSlice";
import { session } from "websdk-core";

const ProfileInitializer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfileData = async () => {
      session.getPopupId().subscribe(
        (res: string) => {
          console.log("Popup ID received:", res);
          dispatch(setPopupId(res));
        },
        (errorRes: any) => {
          console.error("Error receiving popup ID:", errorRes);
        }
      );
    };

    fetchProfileData();
  }, [dispatch]);

  return null;
};

export default ProfileInitializer;
