/**
 * Utility function to format error messages consistently.
 *
 * @param error - The error to format
 * @returns A formatted error message as a string
 */
export const formatError = (error: any): string => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "object" && error !== null) {
    return JSON.stringify(error, null, 2);
  } else {
    return String(error);
  }
};
