import StompClientService from "../stompClientService";
import { session } from "websdk-core";

export const getActiveTaskHandler = (replyToQueue: string | null) => {
  try {
    session.getActiveTask().subscribe({
      next: (data: any) => {
        const response = JSON.stringify(data);
        if (replyToQueue) {
          StompClientService.publish(replyToQueue, response);
        } else {
          console.error("No reply-to destination specified in message headers");
        }
      },
      error: (error: Error) => {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        console.error("Error retrieving active task:", errorMessage);
        const response = JSON.stringify({ error: errorMessage });
        if (replyToQueue) {
          StompClientService.publish(replyToQueue, response);
        }
      },
    });
  } catch (err) {
    console.error("Unexpected error:", err);
  }
};
