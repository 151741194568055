import { session } from "websdk-core";
interface ErrorResponse {
  message: string;
}

class TerminalService {
  static sendCommand(command: string): Promise<any> {
    return new Promise((resolve, reject) => {
      session.send(command).subscribe({
        next: (data: any) => resolve(data),
        error: (error: ErrorResponse) => reject(new Error(error.message)),
      });
    });
  }
}

export default TerminalService;
