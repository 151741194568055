import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  topic: string;
  queue: string;
  popupId: string;
  queueActive: boolean;
}

const initialState: ProfileState = {
  topic: "",
  queue: "",
  popupId: "",
  queueActive: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setTopic: (state, action: PayloadAction<string>) => {
      state.topic = action.payload;
    },
    setQueue: (state, action: PayloadAction<string>) => {
      state.queue = action.payload;
    },
    setPopupId: (state, action: PayloadAction<string>) => {
      state.popupId = action.payload;
    },
    setQueueActive: (state, action: PayloadAction<boolean>) => {
      state.queueActive = action.payload;
    },
  },
});

export const { setTopic, setQueue, setPopupId, setQueueActive } =
  profileSlice.actions;
export default profileSlice.reducer;
