import { IMessage } from "@stomp/stompjs";
import { getUserHandler } from "./getUserHandler";
import { getLastCrypticCommandAndResponseHandler } from "./getLastCrypticCommandAndResponseHandler";
import { getTaskListHandler } from "./getTaskListHandler";
import { getActiveTaskHandler } from "./getActiveTaskHandler";
import { getRecordLocatorHandler } from "./getRecordLocatorHandler";
import { defaultHandler } from "./defaultHandler";

export const dispatchMessage = async (message: IMessage) => {
  if (typeof message.body !== "string") {
    console.error("Received non-text message:", message);
    return;
  }

  const newMessage = message.body;
  const messageType = message.headers["type"];
  const replyToQueue = message.headers["reply-to"];

  switch (messageType) {
    case "GetUser":
      getUserHandler(replyToQueue);
      break;
    case "GetLastCrypticCommandAndResponse":
      getLastCrypticCommandAndResponseHandler(replyToQueue);
      break;
    case "GetTaskList":
      getTaskListHandler(replyToQueue);
      break;
    case "GetActiveTask":
      getActiveTaskHandler(replyToQueue);
      break;
    case "GetRecordLocator":
      getRecordLocatorHandler(replyToQueue);
      break;
    default:
      defaultHandler(newMessage, replyToQueue);
      break;
  }
};
