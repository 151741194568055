import { Client, IMessage } from "@stomp/stompjs";
import { maskCreditCard } from "./utils/maskCreditCard";

class StompClientService {
  private client: Client | null = null;
  private subscriptions: {
    [key: string]: { subscription: any; callback: (message: IMessage) => void };
  } = {};

  public setupClient(
    brokerURL: string,
    onConnect?: () => void,
    onStompError?: (frame: any) => void,
    onWebSocketError?: (event: Event) => void,
    onDisconnect?: () => void
  ): void {
    this.client = new Client({
      brokerURL,
      connectHeaders: {},
      onConnect: () => {
        if (onConnect) onConnect();
      },
      onStompError: (frame) => {
        console.error("STOMP error:", frame);
        if (onStompError) onStompError(frame);
      },
      onWebSocketError: (event) => {
        console.error("WebSocket error:", event);
        if (onWebSocketError) onWebSocketError(event);
      },
      onDisconnect: () => {
        console.log("Disconnected from STOMP broker");
        if (onDisconnect) onDisconnect();
        this.subscriptions = {};
      },
    });
  }

  public activate(): void {
    if (this.client) {
      this.client.activate();
    } else {
      console.error("Client is not initialized");
    }
  }

  public deactivate(): void {
    if (this.client) {
      this.client.deactivate();
    } else {
      console.error("Client is not initialized");
    }
  }

  public subscribe(
    topic: string,
    onMessageReceived: (message: IMessage) => void
  ): void {
    if (this.client) {
      const subscription = this.client.subscribe(topic, (message) => {
        if (typeof message.body === "string") {
          onMessageReceived(message);
        } else {
          console.error("Received non-text message:", message);
        }
      });

      this.subscriptions[topic] = { subscription, callback: onMessageReceived };
    } else {
      console.error("Client is not initialized");
    }
  }

  public unsubscribe(topic: string): void {
    if (this.client && this.subscriptions[topic]) {
      this.subscriptions[topic].subscription.unsubscribe();
      delete this.subscriptions[topic];
    } else {
      console.error("No subscription found for topic:", topic);
    }
  }

  public publish(
    destination: string,
    body: string,
    headers: Record<string, string> = {},
    ttl: number = 15000
  ): void {
    if (typeof body !== "string") {
      console.error("Message body must be a string");
      return;
    }

    const maskedBody = maskCreditCard(body);

    if (this.client) {
      const expiration = Date.now() + ttl;

      this.client.publish({
        destination,
        body: maskedBody,
        headers: {
          ...headers,
          expiration: expiration.toString(),
        },
      });
    } else {
      console.error("Client is not initialized");
    }
  }
}

export default new StompClientService();
