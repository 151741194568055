import { configureStore } from "@reduxjs/toolkit";
import messageReducer from "./message/messageSlice";
import userReducer from "./user/userSlice";
import profileReducer from "./profile/profileSlice";

export const store = configureStore({
  reducer: {
    message: messageReducer,
    user: userReducer,
    profile: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
