import { Dispatch } from "redux";
import { addMessage } from "../state/message/messageSlice";
import { session } from "websdk-core";

/**
 * Handler for the 'tasks.activated' event.
 *
 * @param dispatch - Redux dispatch function
 */
export const handleActivatedTask = (dispatch: Dispatch<any>) => {
  return async (res: any) => {
    console.log("Event notification:", res);

    try {
      const activeTask = await new Promise<any>((resolve, reject) => {
        session.getActiveTask().subscribe({
          next: (data: any) => resolve(data),
          error: (error: Error) => reject(error),
        });
      });

      if (activeTask && activeTask.target) {
        await new Promise<void>((resolve) => {
          session.setContext(activeTask.target);
          resolve();
        });
      }
    } catch (error) {
      console.error(
        "Error handling active task:",
        error instanceof Error ? error.message : "Unknown error"
      );
      dispatch(
        addMessage(
          `Failed to handle active task: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        )
      );
    }
  };
};

/**
 * Error handler for the 'tasks.activated' event.
 *
 * @param dispatch - Redux dispatch function
 */
export const handleActivatedTaskError = (dispatch: Dispatch<any>) => {
  return (errorRes: any) => {
    console.error("Event notification error:", errorRes);
    dispatch(
      addMessage(
        `Failed to handle event notification: ${
          errorRes instanceof Error ? errorRes.message : "Unknown error"
        }`
      )
    );
  };
};
