import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for the user state
interface UserState {
  agentSign: string;
  agentInitials: string;
  userData: any; // Replace 'any' with a more specific type if you know the structure
  isLoading: boolean;
  error: string | null;
}

// Set the initial state
const initialState: UserState = {
  agentSign: "",
  agentInitials: "",
  userData: null,
  isLoading: false,
  error: null,
};

// Create the slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUserStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchUserSuccess: (state, action: PayloadAction<Partial<UserState>>) => {
      // Merge the new data with the current state
      Object.assign(state, action.payload);
      state.isLoading = false; // Explicitly set loading to false after success
    },
    fetchUserFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const { fetchUserStart, fetchUserSuccess, fetchUserFailure } =
  userSlice.actions;
export default userSlice.reducer;
