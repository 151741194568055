import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StompClientService from "../services/stompClientService";
import { addMessage } from "../services/state/message/messageSlice";
import { RootState } from "../services/state/store";
import { IMessage } from "@stomp/stompjs";
import { session } from "websdk-core";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";
import { fetchUserData } from "../services/user/fetchUserData";
import { dispatchMessage } from "../services/messageHandlers/messageDispatcher";
import { setQueue, setTopic } from "../services/state/profile/profileSlice";
import {
  handleActivatedTask,
  handleActivatedTaskError,
} from "../services/eventHandlers/activatedTaskHandler";
import {
  handleCreatedTask,
  handleCreatedTaskError,
} from "../services/eventHandlers/createdTaskHandler";
import { registerInterceptor } from "../services/interceptorHandlers/interceptorHandler";
import { formatError } from "../services/utils/formatErrorHandler";
import { apm } from "../index";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const MessageReceiver: React.FC = () => {
  const profileState = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  const onQueueReceived = async (message: IMessage) => {
    apm.addLabels({ event: "queueReceived" });
    dispatch(addMessage(message.body as string));
    await dispatchMessage(message);
  };

  const onTopicReceived = async (message: IMessage) => {
    const messageBody = message.body as string;
    apm.addLabels({ event: "topicReceived", messageBody });

    if (messageBody === profileState.popupId) {
      session.hideSmartTool();
      dispatch(
        addMessage(
          "OK - Queue: " +
            profileState.queue +
            ". Currently: " +
            profileState.queueActive
        )
      );

      StompClientService.unsubscribe(profileState.queue);
      StompClientService.subscribe(profileState.queue, onQueueReceived);
    } else {
      session.showSmartTool();
      session.resizeSmartTool({
        height: 140,
        width: 380,
      });
      apm.addLabels({
        event: "sessionMismatch",
        popupId: profileState.popupId,
      });
      StompClientService.unsubscribe(profileState.queue);
      dispatch(
        addMessage(
          "New session ID did not match: " +
            profileState.popupId +
            ". Disconnection from: " +
            profileState.queue
        )
      );
    }
  };

  const publishMessage = (topic: string) => {
    apm.addLabels({ event: "publishMessage", topic });
    if (topic) {
      StompClientService.publish(topic, profileState.popupId);
    }
  };

  const handleReconnect = () => {
    apm.addLabels({ event: "handleReconnect" });
    if (profileState.topic) {
      publishMessage(profileState.topic);
    }
  };

  const connectClient = async () => {
    const brokerURL = process.env.REACT_APP_WEBSOCKET_URL;
    if (!brokerURL) {
      apm.captureError(new Error("WebSocket URL not defined"));
      return;
    }

    try {
      const logonName = await fetchUserData(dispatch);
      if (!logonName) {
        apm.captureError(new Error("Failed to retrieve logon name"));
        dispatch(addMessage("Failed to retrieve logon name"));
        return;
      }

      const requestQueue = `/queue/Amadeus_Connector_${logonName}_Request`;
      const requestTopic = `/topic/Amadeus.Connector.Session.${logonName}_Request`;

      // Dispatch to store them in the profile state
      dispatch(setQueue(requestQueue));
      dispatch(setTopic(requestTopic));

      StompClientService.setupClient(
        brokerURL,
        () => {
          apm.addLabels({ event: "stompClientConnected" });
          StompClientService.subscribe(requestQueue, onQueueReceived);
          StompClientService.subscribe(requestTopic, onTopicReceived);
          publishMessage(requestTopic);
          apm.addLabels({
            event: "stompClientConnected",
            queue: requestQueue,
            topic: requestTopic,
          });
        },
        (frame) => {
          apm.captureError(new Error(`STOMP error: ${frame}`));
        },
        (event) => {
          apm.captureError(new Error(`WebSocket error: ${event}`));
        },
        () => {
          apm.addLabels({ event: "stompClientDisconnected" });
        }
      );

      StompClientService.activate();
      const eventRegister = session.registerEvent("tasks.activated");

      eventRegister.status.subscribe({
        next: (res: any) => {
          apm.addLabels({
            event: "stompClientConnected",
            message: requestQueue,
          });
          console.log("Event register status:", res);
        },
        error: (errorRes: any) => {
          console.error("Event register status error:", errorRes);
          dispatch(
            addMessage(`Failed to register event: ${formatError(errorRes)}`)
          );
        },
      });

      eventRegister.notification.subscribe({
        next: handleActivatedTask(dispatch),
        error: handleActivatedTaskError(dispatch),
      });

      const createdEvent = session.registerEvent("tasks.created");

      createdEvent.status.subscribe({
        next: (res: any) => {
          console.log("Tasks Created Event status:", res);
        },
        error: (errorRes: any) => {
          console.error("Tasks Created Event status error:", errorRes);
          dispatch(
            addMessage(`Failed to register event: ${formatError(errorRes)}`)
          );
        },
      });

      createdEvent.notification.subscribe({
        next: handleCreatedTask(dispatch, () =>
          registerInterceptor(dispatch, formatError)
        ),
        error: handleCreatedTaskError(dispatch),
      });
    } catch (error) {
      apm.captureError(new Error(`Error connecting to STOMP client: ${error}`));
      dispatch(addMessage("Failed to connect to STOMP client"));
    }
  };

  useEffect(() => {
    connectClient();

    return () => {
      StompClientService.deactivate();
    };
  }, [profileState.queue, profileState.topic]);

  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid",
        borderColor: "error.main",
        borderRadius: 1,
        marginBottom: 2,
        position: "relative",
      }}
    >
      <Typography variant="body1">
        Multiple browsers detected, this window is now disconnected. Click OK to
        re-connect.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleReconnect}>
        OK
      </Button>

      {/* Tooltip with Icon */}
      <Tooltip
        title={`Popup ID: ${profileState.popupId}, Queue: ${profileState.queue}`}
        arrow
      >
        <StyledIconButton>
          <InfoOutlined />
        </StyledIconButton>
      </Tooltip>
    </Box>
  );
};

export default MessageReceiver;
