import StompClientService from "../stompClientService";
import TerminalService from "../command/terminalService";

export const defaultHandler = async (
  newMessage: string,
  replyToQueue: string | null
) => {
  try {
    const data = await TerminalService.sendCommand(newMessage);
    const response = JSON.stringify(data);
    if (replyToQueue) {
      StompClientService.publish(replyToQueue, response);
    } else {
      console.error("No reply-to destination specified in message headers");
    }
  } catch (err) {
    console.error(
      "Error calling Terminal Service:",
      err instanceof Error ? err.message : "Unknown error"
    );
  }
};
