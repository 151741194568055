import { Dispatch } from "react";
import {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
} from "../state/user/userSlice";
import { addMessage } from "../state/message/messageSlice";
import { session } from "websdk-core";

export const fetchUserData = async (
  dispatch: Dispatch<any>
): Promise<string | null> => {
  try {
    dispatch(fetchUserStart()); // Dispatch start action
    return new Promise((resolve, reject) => {
      session.retrieveUser().subscribe({
        next: async (data: any) => {
          try {
            const userData = typeof data === "string" ? JSON.parse(data) : data;

            if (!userData || typeof userData !== "object") {
              throw new Error("Invalid user data structure.");
            }

            const agentSign = userData.AGENT_SIGN || "";
            const agentInitials = userData.AGENT_INITIALS || "";

            if (!agentSign || !agentInitials) {
              throw new Error(
                "Missing AGENT_SIGN or AGENT_INITIALS in user data."
              );
            }

            const combinedString = `${agentSign}${agentInitials}`;
            const baseUrl = process.env.REACT_APP_JUMPIN_ID_URL;

            if (!baseUrl) {
              throw new Error(
                "Base URL is not defined in environment variables."
              );
            }

            const fullUrl = `${baseUrl}${combinedString}`;
            console.log(`Fetching data from URL: ${fullUrl}`);
            dispatch(addMessage(`Fetching data from URL: ${fullUrl}`));

            const response = await fetch(fullUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            const logonName = responseData[0]?.logonName || null;

            dispatch(
              fetchUserSuccess({
                agentSign,
                agentInitials,
                userData: responseData,
              })
            );
            dispatch(
              addMessage(`Fetch Success: ${JSON.stringify(responseData)}`)
            );
            resolve(logonName);
          } catch (error) {
            handleError(error, dispatch);
            reject(null);
          }
        },
        error: (error: any) => {
          handleError(error, dispatch);
          reject(null);
        },
      });
    });
  } catch (error) {
    handleError(error, dispatch);
    return null;
  }
};

const handleError = (error: any, dispatch: Dispatch<any>) => {
  const errorMessage =
    error instanceof Error ? error.message : "An unknown error occurred";
  console.error(errorMessage);
  dispatch(fetchUserFailure(errorMessage));
  dispatch(addMessage(errorMessage));
};
