export function maskCreditCard(response: string): string {
  let modifiedResponse = response;

  // Mask AX and TP patterns
  modifiedResponse = modifiedResponse.replace(
    /(?<=(AX|TP)\d{6})\d{5}(?=\d{4}\D)/g,
    "XXXXX"
  );

  // Mask DC patterns
  modifiedResponse = modifiedResponse.replace(
    /(?<=(DC)\d{6})\d{4}(?=\d{4}\D)/g,
    "XXXX"
  );

  // Mask VI, CA, EC, and MC patterns
  modifiedResponse = modifiedResponse.replace(
    /(?<=(VI|CA|EC|MC)\d{6})\d{6}(?=\d{4}\D)/g,
    "XXXXXX"
  );

  return modifiedResponse;
}
