import { session } from "websdk-core";
import { Dispatch } from "redux";
import { addMessage } from "../state/message/messageSlice";

interface InterceptorResponse {
  data: {
    command: string;
  };
  block: () => Promise<any>;
  release: () => any;
}

export const registerInterceptor = (
  dispatch: Dispatch,
  formatError: (error: any) => string
) => {
  const interceptor = session.registerInterceptor(
    "commandPage.crypticCommand",
    {
      regex: new RegExp("connector show", ""),
    }
  );

  interceptor.status.subscribe({
    next: (res: any) => {
      console.log("Interceptor status success:", res);
    },
    error: (errorRes: any) => {
      console.error("Interceptor status error:", errorRes);

      // Format the error for display
      const formattedError = formatError(errorRes);
      dispatch(addMessage(`Failed to register interceptor: ${formattedError}`));
    },
  });

  interceptor.notification.subscribe({
    next: async (res: InterceptorResponse) => {
      console.log("Notification received:", res.data);

      try {
        // Automatically block the command from executing in Amadeus
        await res.block();
        session.showSmartTool().subscribe({
          next: (toolRes: any) => {
            dispatch(addMessage(toolRes));
          },
          error: (errorRes: any) => {
            console.error("Error showing Smart Tool:", errorRes);
            dispatch(
              addMessage(
                `Failed to show Smart Tool: ${
                  errorRes instanceof Error ? errorRes.message : "Unknown error"
                }`
              )
            );
          },
        });
      } catch (blockError) {
        console.error("Error blocking the command:", blockError);
        dispatch(
          addMessage(
            `Failed to block command: ${
              blockError instanceof Error ? blockError.message : "Unknown error"
            }`
          )
        );
      }
    },
    error: (errorRes: any) => {
      console.error("Notification error:", errorRes);
    },
  });
};
